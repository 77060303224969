import React from 'react';
// import { graphql, useStaticQuery } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_2/sub_1/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(
      frontmatter: { language: { eq: "PL" }, title: { eq: "slide-2-1-2" } }
    ) {
      body
    }
    file(relativePath: { eq: "chapter_2/sub_1/mobile/2-1-2-bg__mobile.png"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
