import React from 'react';
// import { graphql, useStaticQuery } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_2/sub_1/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    body: mdx(
      frontmatter: { language: { eq: "PL" }, title: { eq: "slide-2-1-2" } }
    ) {
      body
    }
    hoverDot: mdx(
      frontmatter: {
        language: { eq: "PL" }
        title: { eq: "romanov-interactive-photo" }
      }
    ) {
      exports {
        hoverInfo {
          positionLeft
          positionTop
          insideText
          identifier
        }
      }
    }
    activeModals: allMdx(filter: {frontmatter: {language: {eq: "PL"}, title: {eq: "romanov-interactive-photo-modal"}}}) { edges { node { body frontmatter { selector } } } }
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
